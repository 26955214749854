@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Montserrat:wght@100;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,500;1,400&family=Poppins:ital,wght@0,100;0,400;1,200;1,300;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;0,700;1,100;1,300;1,500;1,700&display=swap');

:root {
  --primaryColor: #fff;
  --secondaryColor: #FD005B;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  list-style-type: none;
  text-decoration: none;
}

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background-color: #191C26;
}

.bgScroll {
  background-color: #191C26;
  color: var(--primaryColor);
  border-bottom: 1px solid var(--primaryColor);
  transition: all 1s ease-in-out;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

nav .navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 5%;
  gap: 10px;
  height: 84px;
  width: 100% !important;
  margin: 0 auto;
  background: #191C26;
}

.logo {
  font-size: 1.75rem;
  font-family: 'Montserrat', 'Poppins', sans-serif;
  width: 332px;
  font-weight: 700;
  line-height: 34px;
  background: linear-gradient(90deg, #FD005B 0%, #BB00FD);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo .span {
  font-size: auto;
  width: 600px;
}

.nav-links {
  display: flex;
}

.nav-links li {
  display: inline-block;
}

.nav-links a {
  margin-left: 35px;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
  transition: color 0.5s;
}

.nav-links a:hover {
  color: var(--secondaryColor);
}

.menuBtn .menu {
  width: 30px;
  cursor: pointer;
  visibility: hidden;
  background: var(--primaryColor);
  border-radius: 2rem;
  font-size: 40px;
  padding: 7px;
}

.menuBtn .menu.icon {
  font-size: 3rem;
  height: 3rem;
  width: 3rem;
}

section {
  padding: 0 6%;
}

.home {
  width: 100%;
  height: 87vh;
  min-height: 500px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(./Assets/Rectangle\ 5.png);
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home .container {
  text-align: center;
  margin: auto;
}

.home-text {
  padding: 100px 0;
  margin: auto;
}

.home-text .first-p {
  font-size: clamp(40px, 8vw, 65px);
  font-weight: 700;
  line-height: 100%;
  vertical-align: top;
  width: 100%;
  max-width: 1210px;
  height: 158px;
  margin-top: 95px;
  margin-left: 30px;
  color: var(--primaryColor);
}

.home-text .second-p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.15rem;
  color: #f1f1f1;
  font-weight: 400;
  margin-top: 12px;
}

.home-text .cta {
  margin-top: 500px;
}

.cta {
  margin-top: -50px;
  text-align: center;
  width: 178px;
  height: 60px;
  background: url(./Assets/ButtonBg.png);
  background-repeat: no-repeat;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  position: absolute;
  left: 44%;
}


.cta a {
  width: 177px;
  height: 59px;
  padding: 20px 35px;
  background: transparent;
  border-radius: 40px;
  font-size: 1.12rem;
  text-transform: capitalize;
  font-weight: 700;
  color: var(--primaryColor);
}

.cta a:hover {
  background: linear-gradient(90deg, #fd005b 0%, #bb00fd 100%);
  width: 177px;
  height: 59px;
}


/* Service Section*/
.service-text {
  padding: 28px 82px;
  position: relative;
  text-align: center;
}

.service-text h2 {
  color: #ffffff0d;
  font-size: 3.8rem;
  text-transform: uppercase;
  margin: 110px 0 25px;
}

.service-text h5 {
  position: absolute;
  left: 44%;
  top: 51%;
  color: var(--secondaryColor);
  text-transform: uppercase;
  font-size: 1.05rem;
}

.service-text p {
  font-size: 1.5rem;
  font-weight: 200;
  color: var(--primaryColor);
  margin-bottom: 25px;
}

.service-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  gap: 50px;
  justify-content: center;
  height: 55vh;
}

.service-box .box {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border: .5px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  transition: transform 0.5s;
  border-radius: 10px;
  width: 300px;
  height: 345px;
}

.service-box .box:hover {
  transform: translateY(-3px);
}

.service-box .box .icon {
  color: var(--primaryColor);
  font-size: 4.3rem;
  margin: 30px 0 15px;
}

.service-box .box h4 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 10px 0 0;
  color: var(--primaryColor);
}

.service-box .box p {
  font-size: .85rem;
  font-weight: 400;
  color: var(--primaryColor);
  margin: 25px 0 30px;
}


.about {
  margin-top: 50px;
  height: 750px;
  background-image: linear-gradient(rgba(25, 25, 35, .80), rgba(25, 25, 38, .80)), url(./Assets/Rectangle\ 2.png);
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 0 5%;
}

.about-text {
  padding: 100px 0 0;
  position: relative;
  text-align: center;
}

.about-text h2 {
  color: #ffffff0d;
  font-size: 3.8rem;
  text-transform: uppercase;
}

.about-text h5 {
  z-index: 9;
  position: absolute;
  left: 45%;
  top: 72%;
  color: var(--secondaryColor);
  text-transform: uppercase;
  font-size: 1.125rem;
}

.text {
  text-align: center;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  height: 70vh;
  width: 100%;
}

.text p {
  margin: 203px 7% 0;
  font-size: 1.5rem;
  font-weight: 300;
  color: #fff;
  line-height: 1.5;
}

.column-container {
  z-index: 999;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  justify-content: space-between;
  gap: 20px;
  margin: 263px 0 30px;
}

.columns {
  display: flex;
  flex-direction: column;
}

.column {
  margin: 20px 0;
  position: relative;
}

.column span {
  position: absolute;
  top: 2%;
  left: -10%;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, .5);
  background-color: rgba(255, 255, 255, .1);
}

.column p {
  font-size: 1.125rem;
  font-weight: 300;
  color: var(--primaryColor);
}

/* Gallery Section */
.gallery {
  padding: 0 6%;
}

.gallery-text {
  padding: 50px 0;
  position: relative;
  text-align: center;
}

.gallery-text h2 {
  color: #ffffff0d;
  font-size: 3.8rem;
  text-transform: uppercase;
}

.gallery-text h5 {
  z-index: 9;
  position: absolute;
  left: 46%;
  top: 44%;
  color: var(--secondaryColor);
  text-transform: uppercase;
  font-size: 1.125rem;
  background: linear-gradient(90deg, #fd005b 0%, #bb00fd 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.video-column {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  gap: 30px;
  justify-content: center;
}

.video-column .video {
  width: 414px;
  border-radius: 10px;
}

/* Contact Section*/
.contact {
  position: relative;
}

.contact-text {
  padding: 120px 0 30px;
  position: relative;
  text-align: center;
}

.contact-text h2 {
  color: #ffffff46;
  font-size: 3.125rem;
  text-transform: uppercase;
}

.contact-text h5 {
  z-index: 9;
  position: absolute;
  left: 45%;
  top: 66%;
  color: var(--secondaryColor);
  font-size: 1.125rem;
  text-transform: uppercase;
}

.form-container {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.overlay2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #191c26b8;
  left: 0;
}

.form {
  z-index: 999;
  padding: 60px 65px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: .8px solid rgba(255, 255, 255, .5);
  width: 80%;
  margin: auto;
  height: 90vh;
}

.form-input {
  display: flex;
  flex-direction: column;
}

.form-control-box {
  display: flex;
}

.input-holder label,
.input-holder label {
  display: block;
  font-size: .85rem;
  color: var(--primaryColor);
  margin: 3px 5px;
  font-weight: 600;
}

.input-holder,
.input-holder select {
  width: 100%;
}

.input-holder input,
.input-holder select {
  width: 430px;
  height: 60px;
  border-radius: 5px;
  margin-left: 5px;
  padding: 21px 25px 18px 20px;
  color: #191c26;
  font-size: 1rem;
  line-height: 1.5;
  outline: none;
  border-color: #fff;
  border: none;
}

.input-holder input:focus {
  outline: none;
}

.input-holder input::-webkit-input-placeholder, .input-holder textarea::-webkit-input-placeholder {
  font-size: .85rem;
  color: rgba(0, 0, 0, 0.4);
}

.input-holder small,
.input-holder small {
  visibility: hidden;
}

.input-holder textarea {
  width: 100%;
  resize: none;
  outline: none;
  padding: 15px;
  font-size: .85rem;
  border-radius: 5px;
  line-height: 1.2;
}

.button-area .btn {
  color: var(--primaryColor);
  display: inline-block;
  width: 100%;
  height: 60px;
  padding: 10px 0;
  text-align: center;
  background: linear-gradient(90deg, #FD005B 0%, #BB00FD 100%);
  font-weight: 600;
  border-radius: 30px;
  font-size: 1.125rem;
  border: 0;
  outline: none;
  cursor: pointer;
  margin-top: -5px;
}

.button-area {
  text-align: center;
  margin-bottom: 20px;
}

.call-address {
  padding: 6% 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.call-address .call {
  display: flex;
}

.call .icon {
  padding: 6%;
  border: 1px solid var(--secondaryColor);
  height: 58px;
  width: 58px;
  border-radius: 58px;
  color: var(--secondaryColor);
  font-size: 1.5rem;
  text-align: center;
}

.call .write-up {
  margin-left: 10px;
}

.call .write-up h5 {
  color: var(--primaryColor);
  font-weight: 300;
  font-size: 1.5rem;
}

.call .write-up p {
  margin-top: 10px;
  font-size: .85rem;
  color: var(--primaryColor);
  font-weight: 400;
}

 .form-container .underline {
  border: solid .3px rgba(255, 255, 255, .3);
  margin: none;
  padding: none;
}

.form-container hr {
  margin: 20px 0;
  height: .5px;
  background-color: #c5bfbf3b;
  border: none;
}

footer {
  padding: 3% 5%;
  display: flex;
  justify-content: space-between;
}


footer .footer-copyright p {
  color: var(--primaryColor);
  font-size: .85rem;
}

footer .logo {
  font-size: 2.1rem;
  width: 440px;
  font-weight: 700;
}

@media screen and (min-width:180px) and (max-width:576px) {
  .menuBtn .menu {
      visibility: visible;
    }
  
    .logo {
      font-size: 1.2rem;
    }

    .nav-links {
          position: absolute;
          top: 8vh;
          right: 0;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          width: 150px;
          min-height: 92vh;
          background-color: var(--secondaryColor);
          transition: transform 0.5s;
          transform: translateX(150px);
        }

            .active-nav {
              transform: translateX(0);
            }
      
            .close-nav {
              transform: translateX(150px);
            }

                .nav-links a {
                  font-size: .7rem;
                  font-weight: 500;
                }
        
                .home-text {
                  padding: 0 0 50px;
                  text-align: center;
                  align-items: center;
                }
        
                .home-text .first-p {
                  font-size: 2rem;
                  padding-right: 35px;
                }
        
                .home-text .second-p {
                  font-size: .8rem;
                }
        
                .home-text p {
                  font-size: .7rem;
                }

                    .home .cta {
                      left: 27%;
                    }
          
                    .cta {
                      margin-top: -30px;
                    }
          
                    .cta a {
                      width: 178px;
                      height: 60px;
                      font-size: 1rem;
                    }
          
                    .cta a:hover {
                      width: 177px;
                      height: 59px;
                      font-size: 1.05rem;
                    }

                 .service-text h2 {
                   font-size: 2.7rem;
                   margin: 60px 0 15px;
                   
                   position: relative;
                   left: -16%;
                 }
        
                 .service-text h5 {
                   font-size: 0.9rem;
                   left: 28%;
                   top: 35%;
                 }
        
                 .service-text p {
                   font-size: 1.2rem;
                 }
                .service-box {
                  height: auto;
                  margin-top: 30px;
                  justify-items: center;
                }
        
                .service-box .box {
                  width: 80%;
                  height: auto;
                }
        
                .service-box .box .icon {
                  font-size: 3rem;
                  margin: 20px 0 10px;
                }
        
                .service-box .box h4 {
                  font-size: 1.2rem;
                  margin: 10px 0 0;
                }
        
                .service-box .box p {
                  font-size: .8rem;
                  margin: 15px 0 20px;
                }

              .about {
                height: 50%;
                padding: 0;
              }

            .about-text {
              padding: 30px 0 0;
            }

            .about-text h2 {
              font-size: 2.7rem;
            }

            .about-text h5 {
              margin-top: 5px;
              transform: translate(-30%, -45%);
              bottom: -19%;
              font-size: .9rem;
            }
        .text {
          height: auto;
          position: relative;
          margin-top: 10%;
        }

        .text p {
          margin: 70px 7% 0;
          font-size: 1.2rem;
        }
    
.column-container {
  margin-top: 50px;
}

.columns {
  padding: 0 40px;
}

.gallery-text h2 {
  font-size: 2.7rem;
}

.gallery-text h5 {
  margin-top: 6px;
  transform: translate(-47%, -45%);
}

.video-column {
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
}

.video-column .video {
  width: 350px;
  border-radius: none;
  margin: 0 20px;
}

.contact-text {
  padding: 60px 0 20px;
}

.contact-text h2 {
  font-size: 2.5rem;
}

.contact-text h5 {
  margin-top: 7px;
  transform: translate(-40%, -85%);
  font-size: 1rem;
}

.form-container {
  background: none;
}



.form-input {
  flex-direction: column;
}

.form-control-box {
  flex-direction: column;
}

.form {
  padding: 40px;
  width: 90%;
  height: auto;
}

.input-holder input,
.input-holder select {
  width: 100%;
}

.call-address {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 7% 0 0;
}

.call-address .call {
  margin: 10px;
}

.call .icon {
  padding: 2%;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  font-size: 1rem;
  position: relative;
  right: 10%;
}

.call .fa-envelope {
  left: 10%;
}

.call .fa-phone {
  left: -9%;
}

.call .fa-house {
  left: 2%
}


.call .write-up h5 {
  color: var(--primaryColor);
  font-weight: 300;
  font-size: 1.2rem;
}

.call .write-up p {
  font-weight: 400;
  font-size: .75rem;
  margin-top: 10px;
}
   
footer {
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

footer .logo {
  font-size: 1rem;
}

footer .footer-copyright p {
  font-size: 0.5rem;
}
}

 @media  screen and (min-width: 577px) and (max-width: 768px) {
  nav .navbar {
    padding: 50px 5%;
    height: 70px;
  }

  .logo {
    width: 100%;
    height: 30px;
  }

  .logo .span {
    display: none;
  }

  .menuBtn .menu {
    font-size: larger;
  }

  .nav-links {
    position: absolute;
      top: 8vh;
      right: 0;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 150px;
      min-height: 92vh;
      background-color: var(--secondaryColor);
      transition: transform  ease-in-out 0.5s;
      transform: translateX(150px);
  }

    .active-nav {
      transform: translateX(0);
    }
  
    .close-nav {
      transform: translateX(150px);
    }

  .nav-links li {
    display: block;
    margin: 15px 0;
  }

  .nav-links a {
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
  }

  .menuBtn .menu {
    visibility: visible;
  }

  section {
    padding: 0 3%;
  }

  .home {
    height: auto;
    min-height: 0;
    margin-top: 40px;
  }

  .home-text {
    padding: 80px 0;
  }

  .home-text .first-p {
    font-size: clamp(30px, 6vw, 50px);
    margin-top: 60px;
    margin-left: 0;
  }

  .home-text .cta {
    margin-top: 30px;
  }

  .cta {
    position: static;
    margin: 70px auto;
  }

  .service-text {
    padding: 28px 20px;
  }

  .service-text h2 {
    font-size: 2.7rem;
    margin: 90px 0 25px;
  }

  .service-text h5 {
    left: 40%;
    top: 48%;
    font-size: .9rem;
  }

  .service-box {
    height: auto;
    margin-top: 40px;
  }

  .service-box .box {
    width: 100%;
    height: auto;
  }

    .about {
      height: 50%;
      padding: 0;
    }
  
    .about-text {
      padding: 30px 0 0;
    }
  
    .about-text h2 {
      font-size: 2.7rem;
    }
  
    .about-text h5 {
      margin-top: 5px;
      transform: translate(-30%, -45%);
      bottom: -19%;
      font-size: .9rem;
    }
  
    .text {
      height: auto;
      position: relative;
      margin-top: 10%;
    }
  
    .text p {
      margin: 70px 7% 0;
      font-size: 1.2rem;
    }
  
    .column-container {
      margin-top: 50px;
    }
  
    .columns {
      padding: 0 40px;
    }

        .gallery-text h2 {
          font-size: 2.7rem;
        }
    
        .gallery-text h5 {
          margin-top: 6px;
          transform: translate(-33%, -44%);
        } 

        .video-column {
          padding: 0 20px; 
         display: grid;
        grid-template-columns: repeat(2, 400px);
        gap: 30px;
        justify-content: center;
      }

            .video-column video {
              width: 330px;
              border-radius: 5px;
            }

        .form {
          width: 100%;
          padding: 70px;
          height: auto;
        }
    
        .input-holder input,
        .input-holder select {
          width: 90%;
          margin: 10px 0;
        }

        .input-holder .move {
          width: 300px;
        }
  

    
        .call-address {
          margin-top: 20px;
        }
    
        .call {
          margin-bottom: 10px;
        }
    
        .call .write-up {
          margin-left: 0;
          text-align: center;
        }

        footer .logo {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }
} 

@media screen  and (min-width:769px) and (max-width:1024px) {
    .home-text .first-p {
      font-size: clamp(40px, 8vw, 45px);
      max-width: auto;
      height: auto;
      margin-bottom: 10px;
    }
  
    .home-text .second-p {
      margin-top: 30px;
    }

        .service-box {
          grid-template-columns: repeat(2, minmax(200px, auto));
          height: auto;
        }
    
        .service-box .box {
          width: 345px;
          height: 345px;
        }

        .video-column {
          grid-template-columns: repeat(2, 400px);
        }

                .form {
                  height: 100%;
                }
        
                .input-holder label,
                .input-holder label {
                  margin: 10px 5px;
                }
        
                .input-holder input,
                .input-holder select {
                 width: 100%; 
                 margin-left: -1px;
                }
}

@media screen and (min-width:1025px) and (max-width:1300px) {
  .home-text .first-p {
      font-size: clamp(40px, 8vw, 45px);
      max-width: auto;
      height: auto;
      margin-bottom: 10px;
    }
  
    .home-text .second-p {
      margin-top: 30px;
    }
  
    .service-box {
      grid-template-columns: repeat(2, minmax(250px, auto));
      height: auto;
    }
  
    .service-box .box {
      /* width: 345px; */
      width: 100%;
      height: 100%;
    }
  
    .video-column {
      grid-template-columns: repeat(2, 400px);
    }
  
    .form {
      height: 100%;
    }

    
  
    .input-holder label,
    .input-holder label {
      margin: 10px 5px;
    }
  
    .input-holder input,
    .input-holder select {
      width: 100%;
    }
}

@media screen and (min-width:769px) and (max-width:912px) {
.logo {
  font-size: 1.2rem;
}

  footer .logo {
    font-size: 1.7rem;
  }
}

